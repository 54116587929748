
/*default*
- kolor tla
- kolor tekstu
- czcionka
*/
$def-color-bg:#9a1818;
$def-color-text: #fff;
$def-font-family: 'Roboto Condensed', sans-serif;


/*navigacja menu górne + flagi*
- kolor tla
- kolor tla: hover;
- kolor tekstu
- kolor tekstu: hover;
- czcionka
*/

$nav-color-bg:#404040;
$nav-color-text:#fff;
$nav-font-family:'Oswald', sans-serif;
/* element listy*/
$nav-list-bg:  #262626;  
$nav-list-bg-hover: black;
$nav-list-text-hover:  white;  


/*przycisk call to action 1
- kolor tla
- kolor tekstu
- czcionka
*/


$cta1-color-bg: #9a1818;
$cta1-color-text:#fff;
$cta1-font-family:'Oswald', sans-serif;
$cta1-font-size:2em;


/*przycisk call to action 2
- kolor tla
- kolor tekstu
- czcionka
*/
$cta2-color-bg:#000;


/*content
*/

$content-color-bg:black;
$content-color-text:white;
$content-opacity:0.7;


/*dolne menu
- kolor tla


- kolor border left
- kolor border left: hover;
- kolor tekstu h1
- kolor tekstu h1: hover;
- kolor tekstu h2
- kolor tekstu h2: hover;
- czcionka
*/

$footer-color-bg:#404040;
$footer-color-bg-hover:#9a1818;
$footer-color-border-left: #9a1818;
$footer-color-h1: white;
$footer-color-h1-hover: white;




/*stopka
- kolor tla
- kolor tekstu
- kolor linków
- kolor linków hover
- kolor linkow background hover
- czcionka
*/

$footer-color-text-h2: silver;
$footer-color-text-h2-hover: white;



/*==========================*/



 /*call to action */

 $font-family-cta: 'Oswald', sans-serif;
 $font-size-cta: 2em;
 $color-text-cta:white;
 $color-bg-cta-first: #9a1818;
 $color-bg-cta-second: black;

/* footer 1*/

$color-bg-foo1: #9a1818 ;
$color-text-foo1: white;
$color-subtext-foo1: silver;


/* footer 2*/

$color-bg-foo1: #9a1818 ;
$color-text-foo1: white;
$color-subtext-foo1: silver;



$color-subtext-foo:white;



