@import "settings";


body {
    position: relative;
    font-family: 'Open Sans Condensed', sans-serif;
}

h3 {
    font-family: "Oswald", sans-serif;
    font-size: 1.8em;
}


.pure-g [class*=pure-u]:not(form) {

    // border: 1px solid black;
    box-sizing: border-box;
    text-align: center;
}


html,
body {
    height: 100%;
    margin: 0;
    background-color: #000;
    font-family: "Oswald", sans-serif;
}



.content {
    padding: 0px;
    min-height: 100%;
    min-width: 100%; // margin: 0 auto -200px;
}







.zawartosc {
    
    p, ul, h1, h2, h3, h4 {    
        font-family: 'Open Sans Condensed', sans-serif;
        text-align: left;
        }
    
}






.menu-bottom {
    margin-left: auto;
    margin-right: auto;
    width: 85%;
}

.menu-bottom div {
    border-left: 5px solid #9a1818;
    padding-left: 20px;
    margin: 10px 0;
}

.menu-bottom div:hover {
    background-color: #9a1818;
    color: white;
}


.menu-bottom h4,
.menu-bottom p {

    font-family: "Oswald", sans-serif;
    font-size: 1em;
    text-align: left;
}

.menu-bottom h4 {
    line-height: 1.2em;
}


.menu-bottom p {
    font-size: .8em;
    margin-top: -14px;
}



.z-index {
    z-index: 1;
    width: 180px;
    height: auto;
    position: absolute;
    top: 0;
    left: 36%;
}


footer {
    text-align: center;
}

.photo {
    background-image: url(../images/photo.jpg);
    background-size: cover;
    background-position: center;
    height: 90%;
}


.page {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
}






.logotypy {
    width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.bg-brown {
    background-color: #9a1818;
    color: white;
    /*height: 100px;*/
}

.bg-silver {
    background-color: #404040;
    color: white;
    /*height: 40px;*/
}

.bg-white {
    background-color: white;
    color: black;
    height: 800px;
}


.bg-black {
    background-color: black;
    color: white;
    /*min-height: 100px;*/
}

.zawartosc {
    margin: 2% 10%;
    padding: 4%;
    text-align: left;
    background-color: black;
    opacity: .8;
    color: white;
    /*min-height: 100%;*/
    /*min-height: 550px;*/
    font-family: "Roboto Condensed", sans-serif; // 'bebas_neueregular','Roboto Condensed', sans-serif
}

.zawartosc-home {

    background-color: inherit;
    min-height: 600px;
    opacity: 1;


    a {
        font-family: "Oswald", sans-serif;
        font-size: 2.4em;
        padding: .4em;

        font-weight: bold; // border: 1px solid black; // background-color: white;
    }

    .label-available,
    .label-check {
        opacity: 1;
        background-color: brown;
        margin-top: 15%;

        a {
            text-decoration: none;
            color: white;
            text-align: center;
        }
    }
    .label-check {
        margin-top: 0;
        background-color: #000;
    }
}

.right {
    float: right;
    margin-right: 5%;
}

.zawartosc-contact {
    p {
        margin: 2px;
        font-size: .9em;
    }
}


.logo-offroad {
    background-image: url(../images/logo3.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100px;
}


.logo-rough {
    background-image: url(../images/logo1.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100px;
}

@media screen and (min-width: 48em) {

     .logo-offroad {
        background-position: left;
    }


     .logo-rough {
        background-position: right;
    }


}








// .logo-distributor {}


.pure-g .menu div {


    border-top: 2px solid #404040;
    font-family: "Oswald", sans-serif;
}

.pure-g .menu {

    color: #1EAEDB;
    font-size: 1.4em;
    /*background-color: #fff;*/
}

.pure-g a {
    font-family: "Oswald", sans-serif;
    color: #1EAEDB;
}

ul.f32 {
    margin-right: 8%;
}

nav ul,
nav ul li {
    display: block;
    list-style: none;
}

nav ul li {
    display: inline;
    white-space: nowrap;
    margin-right: 12px;
}

nav ul li a {
    font-size: 1.4em;
    text-decoration: none;
}

ul li {
    text-decoration: none;
    list-style: none;
}

.language a {
    font-size: 0;
}

.flag {
    font-size: 0;
}


    .google-maps {
        position: relative;
        padding-bottom: 35%; // This is the aspect ratio
        height: 0;
        overflow: hidden;
    }
    .google-maps iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }

    .pure-form {
        color: black;
        text-aling: center;

    }

    form h3, form p
    {
        color: white;
    }

    textarea {
        height: 120px;
    }